<template>
  <div>
    <div class="buttons-container">
      <router-link :to="{ name: 'MonitoringRehabilitators' }">
        <Button title="Tableaux" type="primary" />
      </router-link>
      <router-link :to="{ name: 'MonitoringRehabilitatorsCharts' }">
        <Button title="Graphs" type="primary" :active="true" />
      </router-link>
    </div>

    <div class="charts-container">
      <div class="rehabilitators-list">

        <p class="title">Vue</p>

        <div class="buttons-container-view">
          <Button
            title="3 derniers mois"
            type="primary"
            size="small"
            :active="viewRange === 'last3months'"
            @click="viewRange = 'last3months'"
          />
          <Button
            title="Depuis le début"
            type="primary"
            size="small"
            :active="viewRange === 'fromStart'"
            @click="viewRange = 'fromStart'"
          />
        </div>

        <div class="rehabilitators-search">
          <Input
            title="Rechercher"
            name="search"
            v-model="search"
          />
        </div>

        <div class="buttons-container">
          <Button
            title="Tous"
            type="primary"
            size="small"
            :active="selectedRehabilitators.length === filteredRehabilitators.length"
            @click="selectAllRehabilitators"
          />
          <Button
            title="Aucun"
            type="primary"
            size="small"
            :active="selectedRehabilitators.length === 0"
            @click="unSelectAllRehabilitators"
          />
        </div>

        <p class="title">Rééducateurs</p>

        <div v-for="rehabilitator in filteredRehabilitators" :key="rehabilitator.reeducatorId"
          class="rehabilitator"
          :class="{ selected: selectedRehabilitatorsIds.includes(rehabilitator.reeducatorId) }"
          :data-color="rehabilitator.color"
          @click="toggleRehabilitator(rehabilitator)"
        >
          <div class="dot" :style="{ background: selectedRehabilitatorsIds.includes(rehabilitator.reeducatorId) ? rehabilitator.color : '#8E8E95' }"></div>
          {{ rehabilitator.firstName }} {{ rehabilitator.lastName }}

          <transition name="fade">
            <div class="loading" v-if="selectedRehabilitatorsIds.includes(rehabilitator.reeducatorId) && !rehabilitator.computedStats">
              <SVGSpinner />
            </div>
          </transition>
        </div>
      </div>

      <transition name="fade" mode="out-in">
        <div class="charts" v-if="selectedRehabilitators.length > 0" key="charts">
          <div class="chart">
          </div>
        </div>
        <div class="charts empty" v-else key="charts-empty">
          <p>Sélectionner un ou plusieurs rééducateurs pour visualiser leurs statistiques</p>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import Button from '@/components/Button'
import Input from '@/components/Forms/Fields/Input'
import SVGSpinner from '@/assets/icons/spinner-tail.svg'

export default {
  name: 'MonitoringRehabilitatorsCharts',
  components: {
    Button, Input, SVGSpinner
  },
  data () {
    return {
      viewRange: 'last3months',
      search: ''
    }
  },
  async created () {
    await this.$store.dispatch('admin/getRehabilitators')
  },
  computed: {
    ...mapGetters({
      rehabilitatorsList: 'admin/rehabilitators',
      selectedRehabilitatorsIds: 'admin/selectedRehabilitators'
    }),
    sortedRehabilitators () {
      return _.sortBy(this.rehabilitatorsList, ['name'])
    },
    filteredRehabilitators () {
      return this.sortedRehabilitators.filter(rehabilitator => rehabilitator.firstName.toLowerCase().includes(this.search.toLowerCase())  || rehabilitator.lastName.toLowerCase().includes(this.search.toLowerCase()))
    },
    selectedRehabilitators () {
      return this.sortedRehabilitators.filter(rehabilitator => this.selectedRehabilitatorsIds.includes(rehabilitator.reeducatorId))
    }
  },
  methods: {
    toggleRehabilitator (rehabilitator) {
      if(this.selectedRehabilitatorsIds.includes(rehabilitator.reeducatorId)) {
        this.$store.dispatch('admin/unselectRehabilitator', rehabilitator.reeducatorId)
      } else {
        this.$store.dispatch('admin/selectRehabilitator', rehabilitator.reeducatorId)
      }
    },
    selectAllRehabilitators () {
      this.sortedRehabilitators.forEach(rehabilitator => {
        this.$store.dispatch('admin/selectRehabilitator', rehabilitator.reeducatorId)
      })
    },
    unSelectAllRehabilitators () {
      this.sortedRehabilitators.forEach(rehabilitator => {
        this.$store.dispatch('admin/unselectRehabilitator', rehabilitator.reeducatorId)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons-container{
  margin-top: 24px;
  display: flex;

  a{
    margin: 0 6px;

    &:first-child{
      margin-left: 0;
    }
    &:last-child{
      margin-right: 0;
    }
  }
}

.charts-container{
  display: flex;
  align-items: flex-start;
  margin-top: 24px;

  .rehabilitators-list{
    position: sticky;
    top: 0;
    width: 15%;
    border-radius: 10px;
    border: 1px solid #F0F0F0;
    padding: 0 0 15px 0;

    .buttons-container-view{
      margin-top: 0;
      flex-direction: column;

      .button{
        margin-bottom: 10px;
      }
    }

    .buttons-container{
      margin-top: 0;
      justify-content: center;
    }

    .title{
      font-size: 1.2rem;
      font-weight: 420;
      margin: 12px 0;
      padding: 0 10px;
    }

    .rehabilitator{
      padding: 5px 10px;
      cursor: pointer;
      white-space: nowrap;
      transition: all 0.25s;
      position: relative;

      &:hover{
        color: $main-color;
      }

      .dot{
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 12px;
        background: $light-text-color;
        display: inline-block;
      }

      .loading{
        width: 20px;
        height: 20px;
        position: absolute;
        left: 5px;
        top: 3px;

        svg{
          width: 100%;

          path{
            stroke: $main-color;
          }
        }
      }

      &.selected{
        font-weight: 420;
      }
    }
  }

  .charts{
    overflow: scroll;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
    border-radius: 10px;
    border: 1px solid #F0F0F0;
    width: 85%;
    padding: 10px;
    margin-left: 24px;

    &.empty{
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: stretch;

      p{
        font-size: 1.2rem;
        font-weight: 420;
      }
    }
  }
}
</style>
